<template>
    <div class="font-poppin">
        <div class="filter-header">
            <div class="fs-14 font-weight-bold ml-4">Filters (<span style="color:#ffba00 !important;">{{totalFilters}}</span>)</div>
            <div class="pointer">
                <span @click="update()">
                    <img class="filter-Images" src="/static/images/filter_check_blue.svg" style="width: 35px;" alt="arrow">
                </span>
                <span v-if="totalFilters == 0">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span v-else @click="resetFilter()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/CloseBgBlue.svg" alt="close">
                </span>
            </div>
        </div>
        <div class="container filter-content">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Started On</div>
                        <date-picker class="profile-date-picker calendar-edit-per input-field-underline pr-0" style="background-color: #e6ecff !important;margin-top: 10px;" v-model="temp_started_on" value-type="format" lang="en" :range="true" :confirm="true" type="date" :clearable="false"
                            :editable="false" format="DD/MM/YYYY"></date-picker>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">    
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Code</div>
                        <multiselect :limit="1" :loading="is_code_loading" :limit-text="limitCode" :show-labels="false" v-model="temp_code" style="background-color: #e6ecff !important;" track-by="id" label="code" :blockKeys="['Delete']" @remove="removeCode" @search-change="searchCodes" placeholder="Select Code" class="diginew-multiselect diginew-multiselect-underline mt-2" :options="code_options" @open="getListViewData('business_code')" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="true" :options-limit="3000" :max-height="200" :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option.code }}</span>
                                    <span class="custom__remove pointer" @click="removeCode(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>     
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">   
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Country</div>
                        <multiselect :limit="1" :limit-text="limitCountry" :show-labels="false" v-model="temp_country" style="background-color: #e6ecff !important;" :blockKeys="['Delete']" @remove="removeCountry" @search-change="searchCountry" placeholder="Select Country" class="diginew-multiselect diginew-multiselect-underline mt-2" :options="country_options" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="true" :options-limit="3000" :max-height="200" :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option }}</span>
                                    <span class="custom__remove pointer" @click="removeCountry(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>    
                <div class="col-lg-6 col-md-6 col-sm-12">   
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Business Units</div>
                        <multiselect :limit="1" :loading="is_bus_unit_loading" :limit-text="limitBusUnit" :show-labels="false" v-model="temp_business_unit" @open="getListViewData('business_unit')" style="background-color: #e6ecff !important;" track-by="id" label="name" :blockKeys="['Delete']" @remove="removeBusinessUnit" @search-change="searchBusinessUnit" placeholder="Select Business Unit" class="diginew-multiselect diginew-multiselect-underline mt-2" :options="business_unit_options" :multiple="true" :searchable="true" :internal-search="true" :clear-on-select="false" :close-on-select="true" :options-limit="3000" :max-height="200" :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option.name }}</span>
                                    <span class="custom__remove pointer" @click="removeBusinessUnit(option)">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>          
        </div>
    </div>    
</template>
<script>
import { EventBus } from '../eventBus/nav-bar-event';
import countries from '../json/countries.json';
import companies from '../mixins/companies';
export default {
    mixins:[companies],
    props:['showfilterData'],
    data(){
        return{
            temp_started_on: {},
            temp_code: [],
            temp_country: [],
            temp_business_unit: [],
            country_options: countries,
            code_options: [],
            business_unit_options: [],
            isLoading: false,
            isVisible: false,
            count: 0,
            skip: 0,
            limit: 10,
            search_key: '',
            scrollable: false,
            is_code_loading: false,
            is_bus_unit_loading: false,
            loading_type: ''
        }
    },
    methods:{
        limitCode(count) {
			return `+ ${count} more`
        },
        limitCountry(count) {
			return `+ ${count} more`
        },
        limitBusUnit(count) {
			return `+ ${count} more`
        },
        closeFilters(){
            this.hide_filters = false
            this.$emit('hideFilters')
        },
        resetFilter(){
            // if(this.totalFilters > 0){
                this.temp_started_on = []
                this.temp_code = []
                this.temp_country = []
                this.temp_business_unit = [] 
                this.update()
            // }
        },
        searchCodes() {

        },
        searchCountry() {

        },
        searchBusinessUnit() {

        },
        reachedEndOfList() {

        },
        async getListViewData(type) {
            this.list_view_data = [];
            this.loading_type = type
            if(this.loading_type == 'business_code') {
                this.is_code_loading = true
            } else {
                this.is_bus_unit_loading = true
            }
            try {
                let params = {
                    skip: this.skip,
                    limit: this.limit,
                    search_key: this.search_key,
                    company_id: this.$route.params.company_id,
                    code: [],
                    country: [],
                    name: [],
                    started_on: {}
                }
                let response = await this.getListBusinessUnit(params);
                if(response.status_id == 1) {
                    this.code_options = response.response;
                    this.business_unit_options = response.response;
                    // this.list_view_data = response.response;
                    this.total = response.count;
                }
                if(this.loading_type == 'business_code') {
                    this.is_code_loading = false
                } else {
                    this.is_bus_unit_loading = false
                }
            } catch(err) {
                if(this.loading_type == 'business_code') {
                    this.is_code_loading = false
                } else {
                    this.is_bus_unit_loading = false
                }
            }
        },
        update(){
            // if(this.totalFilters > 0){
                let started_on = this.temp_started_on
                let code = this.temp_code.map(code => {return code.code})
                let country = this.temp_country.map(country => {return country})
                let business_unit = this.temp_business_unit.map(bus => {return bus.name})
                let params = {
                    started_on: started_on,
                    code: code,
                    country: country,
                    business_unit: business_unit,
                }
                let params1 = {
                    started_on: started_on,
                    code: code,
                    country: country,
                    business_unit: business_unit,
                }
                let newData = {
                    value:params,
                    value1:params1
                }
                this.closeFilters();
                this.$emit('UpdateByFilter',newData)
            // }
        },
        removeDob(option){
            this.temp_started_on.forEach((el,index)=>{
                if(el.toLowerCase() == option.toLowerCase()){
                    this.temp_started_on.splice(index,1)
                }
            })
        },
        removeCode(option){
            this.temp_code.forEach((el,index)=>{
                if(el.code == option.code){
                    this.temp_code.splice(index,1)
                }
            })
        },
        removeCountry(option){
            this.temp_country.forEach((el,index)=>{
                if(el.country == option.country || el.code == option){
                    this.temp_country.splice(index,1)
                }
            })
        },
        removeBusinessUnit(option){
            this.temp_business_unit.forEach((el,index)=>{
                if(el.name == option.name || el.country == option){
                    this.temp_business_unit.splice(index,1)
                }
            })
        },
    },
    async mounted(){
        this.temp_started_on = this.showfilterData.started_on
        this.temp_code = this.showfilterData.code
        this.temp_country = this.showfilterData.country 
        this.temp_business_unit = this.showfilterData.business_unit
        EventBus.$on('removechip',params=>{
            if(params.key == 'started_on'){
                this.removeDob(params.value)
                this.update()
            }else if(params.key == 'code'){
                this.removeCode(params.value)
                this.update()
            }else if(params.key == 'country'){
                this.removeCountry(params.value)
                this.update()
            }else if(params.key == 'business_unit'){
                this.removeBusinessUnit(params.value)
                this.update()
            }
        })
        EventBus.$on('removeAllchip',() => {
            this.resetFilter()
        })
    },
    destroyed(){
        EventBus.$off('removechip')
        EventBus.$off('removeAllchip')
    },
    computed:{
        totalFilters(){
            let data_length;
            if(Object.keys(this.temp_started_on).length == 0){
                data_length = 0
            }else{
                data_length = 1
            }
            return data_length + this.temp_code.length + this.temp_country.length + this.temp_business_unit.length;
        }    
    }
}
</script>
<style scoped>
.filter-content{
    max-height: 534px !important;
    overflow-x: visible;
    overflow-y: visible;
}
</style>